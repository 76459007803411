.footer {
  height: 80px;
  border-top: 1px solid #e4e4e4;
}
.chats .rightSide {
  display: none;
}

.footerMobile {
  height: auto;
  border-top: 0;
}
.footerContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 17px;
}
.footerContainerMobile {
  border-top: 1px solid #e4e4e4;
  padding: 37px 30px 40px !important;
  align-items: flex-start;
}
.footerContainer p {
  color: #5c5b66;
  font-size: 14px;
}
.links {
  font-size: 14px;
  margin-bottom: 9px;
}
.links>a {
  margin-right: 9px;
}
.links>a:last-child {
  margin-right: 0;
}
.rightSide {
  display: flex;
  flex-direction: column;
}
.rightSide>p {
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 26px;
}
.rightSide>.link {
  margin-right: 12px;
}
.rightSideMobile {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.rightSideMobile > p {
  width: 100%;
  margin: 0 0 26px;
}

.footerContainerMobile .bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 27px 0 0;
}
.footerContainerMobile .bottom .links {
  margin-bottom: 27px;
}
.link:last-child {
  margin-right: 0;
}
.footer .address {
  font-weight: normal;
}
.address svg {
  margin: 0 5px 0 1px;
}
.linkContainer {
  margin-bottom: 44px;
}
.linkContainer a {
  margin-right: 25px;
}
.linkContainer a:last-child {
  margin-right: 0;
}